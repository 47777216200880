@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.striped tr:nth-child(2n) {
	  @apply bg-gray-100
	}
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* a{
  color: blue
} */





table.formtable {
	border: solid #333 1px; background-color: #ebebeb; width: 100%;
}
ul#reportmenu {
	margin: 0px;
	padding: 0px;
	list-style: none;
	display: block;
}
ul#reportmenu li {
	display: block;
	list-style: none;
	margin: 0px;
	padding: 0px;
	border: outset white 1px;
	width: 100%;
	font-family: Arial, sans-serif;
	font-size: 16px;
	font-weight: bold;
}
ul#reportmenu li a {
	display: block;
	margin: 0;
	padding: 6px 10px 6px 20px;
}
ul#reportmenu li a:hover {
	background-color: #dff4ff;
}
ul#reportmenu li a.active {
	background-color: #fff;
}
div.divider {
	margin: 0px 0px 8px 0px;
	border: inset white 1px;
	padding: 10px;
}
select.preselect option {
	/* background-image: url('./images/nav/arrow_right.gif'); */
	background-repeat: no-repeat;
	background-position: 98% 1px;
}
label.disabled {
	color: #999;
}

select#options_list{
	width: 165px;
}
select#employee_list {
	width: 300px;
}


body  { margin: 0px; padding: 0px; 
background-color: #949292;
background-image: url('./images/nav/bg_body.gif');
background-repeat: repeat-x;

}

main {
	/* background-color: #dddddd; */
	padding-bottom: 25px;
}
body#home { background-color: #efefef; }
form { display: block; margin: 0px; padding: 0px }

/* default font family */
body, p, span, div, li, a, td, th, label, input, option, h1, h2, h3, h4, h5, h6 {
font-family: Arial, Trebuchet MS, Tahoma, Verdana, sans-serif;
}
span { line-height: 1.1em; }
a { text-decoration: none; }
a:link { color: #2E87CC; }
a:hover { color: #008EFC; }
a:active { color: #033861; }
a:visited { color: #2E71CC; }
.bodycopy { font-size: 12px; line-height: 1.6em; }
a.menulink:link { color: #323232; font-size: 13px; font-weight: normal;  text-decoration: none; }
a.menulink:visited { color: #323232; font-size: 13px; font-weight: normal;  text-decoration: none; }
a.menulink:hover { color: Blue; font-size: 13px; font-weight: normal;  text-decoration: none; }
a.menulink:active { color: black; font-size: 13px; font-weight: normal;  text-decoration: none; }
a.menulink_current:link { color: black; font-weight: bold; font-size: 13px;  text-decoration: none; }
a.menulink_current:visited { color: black; font-weight: bold; font-size: 13px;  text-decoration: none; }
a.menulink_disabled:link { color: #696969; font-size: 14px;  text-decoration: none; }
select { font-size: 11px; border-width: 1px; color: #000; }
input.textbox, input.textbox_number, textarea {
font-size: 11px;
border: solid 1px #fdfdfd;
border-color: #959595 #f4f4f4 #f4f4f4 #959595;
color: #000;
}
input.textbox:focus, input.textbox_number:focus, textarea:focus { border: solid 1px #2E87CC; }
input.textbox_number { text-align: right; }

input.button, input.button_submit, a.button, span.button, div.button {
font-size: 12px;
background-color: #4992cb;
border: outset 1px #4992cb;
color: white;
background-image: url('./images/nav/bg_button.gif');
background-repeat: no-repeat;
background-position: 50% -5px;
text-align: center;
}
input.button_disabled {
font-size: 12px;
background-color: #cfcfcf;
border: solid 1px #c5c5c5;
color: #efefef;
background-image: url('./images/nav/bg_button_disabled.gif');
background-repeat: no-repeat;
background-position: 50% -5px;
text-align: center;
}
input.button_disabled:active {
background-image: none;
background-color: #efefef;
color: #d6d6d6;
border-style: outset;
}
a.button:visited {
color: white;
}
.button:active, .button_submit:active {
border-style: inset;
}
input.button_submit {
font-weight: bold;
}
a.button, span.button, div.button {
width: auto;
padding-left: 6px;
padding-right: 6px;
height: 12px !important;
padding: 2px 6px 2px 6px !important;
padding: 0px 6px 0px 6px;

}
a.button img, span.button img, div.button img {
margin: 0;
padding: 0;
}

input.button_menu {
/* deprecated */
font-weight: bold;
border-width: 1px;
border-style: solid;
border-color: #696969 #fff #fff #696969;
background-image: url('./images/nav/menubar.gif');
background-repeat: repeat-x;
color: #fff;
font-size: 11px 
}

h1, .h1 { color: #00249b; font-weight: bold; font-size: 24px; }
h2, .h2   { color: #00249b; font-weight: bold; font-size: 20px; }
h3, .h3   { color: #00249b; font-weight: bold; font-size: 18px; }
h4, .h4   { color: #00249b; font-weight: bold; font-size: 16px; }
h5, .h5   { color: #00249b; font-weight: bold; font-size: 14px; }
label, .label {
color: #000;
font-weight: bold;
font-size: 12px;
}
.pagetitle { color: #040F9D; font-weight: bold; font-size: 16px; }
.note, .notes { color: #666; font-weight: normal; font-size: 11px; }
fieldset {
padding: 5px;
background-color: #f1f6fd;
border-width: 1px;
border-color: #DDD;

}
legend { font-size: 13px; color: black; font-weight: bold; padding: 4px;  }
th, .th {
/* text-align: center; */
 background-color: #2a2a2a;
/*background-image: url('./images/nav/bg_th.gif'); */
background-repeat: repeat-x;
font-size: 12px;
color: #fff;

}

.border_bot { border-style: solid; border-color: #9c9c9c; border-width: 0px 0px 1px 0px }
.border_top { border-style: solid; border-color: #9c9c9c; border-width: 1px 0px 0px 0px }
.border_left { border-style: solid; border-color: #9c9c9c; border-width: 0px 0px 0px 1px }
.border_rt { border-style: solid; border-color: #9c9c9c; border-width: 0px 1px 0px 0px }
.border_all { border-style: solid; border-color: #9c9c9c; border-width: 1px }
.border_topbot { border-style: solid; border-color: #9c9c9c; border-width: 1px 0px 1px 0px }
.border_sides { border-style: solid; border-color: #9c9c9c; border-width: 0px 1px 0px 1px }
.border_rb { border-style: solid; border-color: #9c9c9c; border-width: 0px 1px 1px 0px }
.border_tl { border-style: solid; border-color: #9c9c9c; border-width: 1px 0px 0px 1px }
.border_lb { border-style: solid; border-color: #9c9c9c; border-width: 0px 0px 1px 1px }
.border_tr { border-style: solid; border-color: #9c9c9c; border-width: 1px 1px 0px 0px }
.border_inset { border: inset #fff 1px; }
.border_outset { border: outset #fff 1px; }
div.form_menu, table.form_header {
padding: 3px 15px 3px 15px;
/*background-color: #fff;*/
text-align: left;
}
div.logobar { 
padding: 0px;
margin: 0px;
background-image: url('./images/client/bg_logobar.gif');
background-repeat: repeat-x; 
}
div.formitems {
margin-left: auto; margin-right: auto;
background-color: #ebebeb;
padding: 10px;
text-align: center;
}
div.formcontainer {
left: auto;
right: auto;

padding: 15px;
background-color: #dddddd;
}

div#footer {
width: 898px;
height: 14px;
overflow: hidden;
left: auto;
right: auto;
background-image: url('./images/nav/dropshadow_top.png');
background-repeat: repeat-x;
background-position: left top;	
margin-bottom: 26px;
}
table.formtable { }
div.formarea {
background-color: #919191;
}
div.formfooter {
background-color: #2a2a2a;
color: #fff;
padding: 12px;
margin-top: 12px;
background-image: url('./images/nav/bg_th.gif');
background-repeat: repeat-x;
text-align: center;
}
div.formfooter div, div.formfooter label, div.formfooter a, div.formfooter li, div.formfooter td {
color: #fff;
}
div.formarea_padding {
width: 870px;
left: auto;
right: auto;
padding-top: 30px;
padding-bottom: 20px;
}
div.dayofmonth {
border-style: solid; border-color: #000; border-width: 0px 1px 1px 0px;
width: 12px; padding: 1px; text-align: center; color: #000;
font-family: Arial,sans-serif; font-size: 11px; font-weight: bold; background-color: #2a2a2a;
background-image: url('./images/nav/bg_th.gif'); background-repeat: repeat-x;
}
table.list td {
font-family: Arial, sans-serif;
font-size: 10px;
border-style: inset;
border-width: 0px 0px 0px 0px;
}
img.img_disabled {
filter: alpha(opacity=50);
-moz-opacity: .50;
opacity: .50;
}
#divTitleBar {
font-family: Arial, sans-serif;
font-size: 15px;
text-align: left;
padding: 5px 15px;
font-weight: bold;
}
#divTitleBar span.section {
color: #666;
}
#divTitleBar img {
}

/* BEGIN HORIZONTAL MENU ------------------------------------------ */
#mainmenu {
margin: 0px;
padding: 0px;
width: 100%;
background-image: url('./images/nav/menubar.gif');
background-repeat: repeat-x;
height: 28px;
position: relative;
display:'flex';
flex-direction: row;

z-index:998
}
#nav {
/* position: absolute; */
z-index: 999;

}
#nav, #nav ul { /* all lists */
padding: 0;
margin: 0;
list-style: none;
/*line-height: 1;*/
font-family: Arial, sans-serif;
font-size: 12px;
font-weight: bold;
text-align: left;
}

#nav a {

/*width: 14em;*/
text-decoration: none;
padding: 5px 0px 5px 10px;
/* border-style: solid;
border-width: 0px 0px 0px 0px;
border-color: #898989; */
color: #fff;
font-weight: normal;
}
#nav li.divider {
display: block;
overflow: hidden;
background-color: #5f5f5f;
border-style: solid;
border-width: 0px 0px 1px 0px;
border-color: #5f5f5f;
height: 1px;
padding: 0px;
margin: 3px 0px 3px 0px;
}
#nav a:hover {
background-color: #5f5f5f;
}

#nav li { /* all list items */
min-width: 10em; /* width needed or else Opera goes nuts */
width:auto;
display: inline-block;
text-align: center;
}

#nav li ul { /* second-level lists */
position: absolute;
display: table;
table-layout: fixed; /* optional */
background-color: #3c3c3f;
width: 14em;
left: -999em; /* using left instead of display to hide menus because display: none isn't read by screen readers */
text-align: left;
}


#nav li ul li { /* second-level lists */
	text-align: left;
	display: table-cell;
	width: auto;
}

#nav li:hover ul, #nav li.sfhover ul { /* lists nested under hovered list items */
left: auto;
text-align: left;
}

#nav li.menuheader div {
padding-top: 2px;
/* background-image: url('./images/nav/menudiv.gif'); */
border-right: solid 1px #666666;
background-repeat: no-repeat;
background-position: right top;
cursor: pointer;
color: white;
}
#nav li.menuheader {
/* uncomment the following to add an icon to the menu headers */
/*background-image: url('./images/icons/arrow_down.gif');
background-repeat: no-repeat;
background-position: 98% 50%;*/
}

/* END HORIZONTAL MENU ------------------------------------------ */

/* BEGIN TABS MENU ------------------------------------------ */
div.tabs {
margin: 12px 0 0 0;
padding: 0;
/*height: auto !important;*/ height: 23px;
background-image: url('./images/nav/bg_tabs.gif');
background-repeat: repeat-x;
}
div.tabs, div.tabs ul, div.tabs li, div.tabs img, div.tabs a {
line-height: 1em;
}
div.tabs ul {
list-style: none;
margin: 0;
padding: 0px 0px 0px 20px;
display: block;
clear: both;
}
div.tabs li {
display: block;
float: left;
margin: 0; padding: 0;
font-weight: bold;
border-style: solid;
border-width: 1px 1px 0px 1px;
border-color: #f5f5f5 #BEBEBE #fff #fff;
text-align: center;
background-image: url('./images/nav/bg_tab.gif');
background-repeat: repeat-x;
background-color: #efefef;
font-size: 11px;
height: 22px;
overflow: hidden;
}
div.tabs li.active {
background-color: #fff;
background-image:  none;
}
div.tabs li.none {
background-image: none;
background-color: #dcdcdc;
border-width: 0;
}
div.tabs li a {
display: block;
margin: 0;
padding: 5px 10px 4px 10px;
color: #000;
}
div.tabs li a:hover, div.tabs li a:active, div.tabs li a:visited {
color: #000;
}
div.tabs li.none a {
/* meant to display an icon */
padding: 2px 10px 0px 0px;
vertical-align: middle;
}
div.tabs img {
padding:0; margin:0;
}


/* END TABS MENU ------------------------------------------ */

/* BEGIN ALERT OBJECT STYLES ------------------------------------------ */
div.alert, div.alert_ok, div.ALERT_OK {
padding: 10px;
margin: 5px;
background-color: white;
color: green;
text-align: center;
width: auto;
font-weight: normal;
font-size: 14px;
border-style: dotted;
border-color: #333;
border-width: 1px;
background-image: url('./images/nav/icon_alert.gif');
background-repeat: no-repeat;
background-position: 10% 50%;
}
div.ALERT_INFO {
color: #333;
}
div.ALERT_ERROR {
color: red;
}
div.ALERT_CAUTION {
color: orange;
}

div.error {
font-weight: normal;
font-size: 10px;
border-style: dotted;
border-color: #333;
border-width: 1px 0px 0px 0px;
padding: 5px;
width: auto;
}
/* END ALERT OBJECT STYLES ------------------------------------------ */

/* BEGIN helpText OBJECT STYLES ------------------------------------------ */
.help {
	padding-left: 5px;
	display: none;
	font-size: 11px;
	font-family: Arial, sans-serif;
	text-align: left;
}
.help a.helptextrollover {
	font-weight: bold;
}
.help div.helptextvalue {
	font-weight: normal;
	left: 20px;
	padding: 5px;
	width: 150px;
	background-color: #ffffd4;
	border: solid 1px #bababa;
	display: none;
	position: absolute;
	text-align: left;
	/*z-index: 347;*/
}
/* END helpText OBJECT STYLES ------------------------------------------ */

.alt {
background-color: #EFF3F9;
}
.tooltips {
background-color: #ffffd4;
border: solid 1px #bababa;
width: 200px;
padding: 10px;
clear: both;
}



/* Dropdown Button */
.dropbtn {
	/* background-color: #04AA6D; */
	background-color: #3c3c3f;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
	position: relative;
	
	display: flex;
	background-color: #3c3c3f;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content { margin-top: 55px; display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

#main-nav {
	/* position: absolute; */
	display: flex;
	width: 100%;
	left: 310px;
	top: 25px;
	/* background-color: #3c3c3f; */
	background: linear-gradient(#5f5f5f, #3c3c3f);

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#main-nav>ul>li {
	float: left;
	position: relative;
	min-width: 100px;
	text-align: center;
	/* background-color: red; */
}

#main-nav>ul>li>a {

	/* background-color: blue; */
	color:#fff !important;
	text-decoration: none;
	text-transform: uppercase;
	font-family: Arial, sans-serif;
	font-weight: normal;
	padding: 7px 11px;
	font-size: 11px;
	display: block;
}

#main-nav>ul>li:hover>a {
	margin-top: -4;
	color:#fff;
	background: #6c7278;
	/* Old browsers */
	

}

#main-nav>ul>li>ul>li>a{
	font-size: 12px;
	color:#fff;
	z-index: 1000;
}

#main-nav>ul>li>ul>li{
	padding:2px;
	padding-left: 5px;
	text-align: left;
	z-index: 1000;
}

#main-nav>ul>li>ul>li:hover{
	background: #5f5f5f;
	z-index: 1000;
}

#main-nav ul ul {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	/* margin-top: 17px; */
	min-width: 150px;
	background-color: #3c3c3f;
	z-index: 1000;
}

#main-nav>ul>li:hover>ul {
	display: block;
}

#main-nav li.divider {
	display: block;
	overflow: hidden;
	background-color: #5f5f5f;
	border-style: solid;
	border-width: 0px 0px 1px 0px;
	border-color: #5f5f5f;
	height: 1px;
	padding: 0px;
	
	}